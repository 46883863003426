import React from "react";
import { LinearProgress } from "@material-ui/core";
import networkCall from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { config } from "./config";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let metadataIdFromURL = urlParams?.get("metadata_id");
    let metaDataId = localStorage.getItem("metaDataId");
    let tokenFromUrl = urlParams.get("token");
    let token = localStorage.getItem("token");
    if (!(tokenFromUrl && metadataIdFromURL)) {
      window.location.replace(config.qdm_admin_url);
    } else if (!(metaDataId && token)) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
      localStorage.setItem("token", tokenFromUrl);
    } else if (tokenFromUrl !== token) {
      localStorage.setItem("token", tokenFromUrl);
    } else if (metadataIdFromURL !== metaDataId) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
    }
  };

  checkForLatestBuild = () => {
    networkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { loading } = this.state;

    return <>{loading ? <LinearProgress /> : this.props.children}</>;
  }
}

export default AppAuth;
