import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  Drawer,
  Switch,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Header from "../../components/navbars/topNavbar";
import withAllContexts from "../../HOCs/withAllContexts";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../../contexts";
// import { MTableEditRow } from "material-table";
import axios from "axios";
import { config } from "../../config";
// import Alert from "../../components/alert";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import { isDefinitionNode } from "graphql";
import Dialog from "../../components/dialog/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createDocument,
  deleteDocument,
  updateDocument,
} from "../../apis/crud";
import isEqual from "react-fast-compare";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f4f4f5",
  },
  container: {
    padding: "25px",
  },
  tableRow: {
    height: 50,
  },
  tabAlign: {
    display: "flex",
    paddingBottom: "16px",
  },
  drawerContainer: {
    padding: "18px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
}));

const drawerPref = [
  { titles: "Title", placeholder: "User", label: "User", minWidth: "50px" },
  {
    titles: "English",
    placeholder: "Enter your text",
    label: "language",
    minWidth: "180px",
  },
  {
    titles: "French",
    placeholder: "Enter your text",
    label: "Enter your text",
    minWidth: "180px",
  },
  {
    titles: "Spanish",
    placeholder: "Enter your text",
    label: "Enter your text",
    minWidth: "180px",
  },
];
const taste = [
  { no: "1", name: "lane", active: "active" },
  { no: "2", name: "dane", active: "active" },
  { no: "3", name: "pane", active: "active" },
  { no: "4", name: "fane", active: "active" },
];

// const drawerPref = [
//   { titles: "Title", placeholder: "User", label: "User", minWidth: "50px" },
//   {
//     titles: "English",
//     placeholder: "Enter your text",
//     label: "language",
//     minWidth: "180px",
//   },
//   {
//     titles: "French",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
//   {
//     titles: "Spanish",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
// ];

function EventLogging(props) {
  const classes = useStyles();
  const messageHeaderName = "QDM";

  const [fieldChange, setFieldChange] = useState({});
  const [languageDetails, setLanguageDetails] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [isdrawerOpen, setDraweropen] = useState(false);
  const [isEditOpen, setEditOpen] = useState({
    field: "",
    isOpen: false,
  });
  const languageDetailsRef = useRef([]);
  const [editedLanguage, setEditedLanguage] = useState({});
  const [messageAlert, setMessageAlert] = useState({
    isopen: false,
    snackmsg: "client save successfully",
    snacktype: "success",
  });

  //Event Logging
  const [drawerPref, setDrawerPref] = useState([
    {
      titles: "EventName",
      FunctionGroup: "FunctionGroup",
      ScreenName: "ScreenName",
      EventCode: "EventCode",
      EventDisc: "Description",
      Create: "Create",
      Update: "Update",
      Read: "Read",
      Delete: "Delete",
    },
  ]);

  const [eventDetails, setEventDetails] = useState([]);
  const [valueChange, setValueChange] = useState({});
  const eventDetailsRef = useRef([]);
  const [editValue, setEditValue] = useState({
    EventName: "",
    id: "",
    FunctionGroup: "",
    ScreenName: "",
    EventCode: "",
    EventDisc: "",
    Create: false,
    Delete: false,
    Update: false,
    Read: false,
  });
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({
    checkedC: false,
    checkedU: false,
    checkedR: false,
    checkedD: false,
  });
  const [dupVal, setDupVal] = useState({
    EventName: "",
    id: "",
    FunctionGroup: "",
    ScreenName: "",
    EventCode: "",
    EventDisc: "",
    Create: false,
    Delete: false,
    Update: false,
    Read: false,
  });

  const handleSwitch = (event) => {
    const fieldName = event.target.name;
    const checkName = event.target.value;
    setChecked((prev) => {
      return {
        ...prev,
        [checkName]: event.target.checked,
      };
    });

    console.log("Checked", checked);
    setValueChange((prev) => {
      return {
        ...prev,
        [fieldName]: event.target.checked,
      };
    });
  };
  console.log("Checked", checked);

  const editOpen = (events, index) => {
    debugger;
    setEditValue({
      EventName: events.EventName,
      id: events.id,
      FunctionGroup: events.FunctionGroup,
      ScreenName: events.ScreenName,
      EventCode: events.EventCode,
      EventDisc: events.Description,
      Create: events.Create,
      Delete: events.Delete,
      Update: events.Update,
      Read: events.Read,
    });

    setOpen(true);

    setChecked((prev) => {
      return {
        ...prev,
        checkedC: events.Create,
        checkedU: events.Update,
        checkedR: events.Read,
        checkedD: events.Delete,
      };
    });
  };

  console.log(editValue, "Edit Value");

  const editClose = (languages) => {
    // setEditOpen((prev) => {
    //   return { ...prev, isOpen: false, field: "" };
    // });
    // setEditValue((prev) => {
    //   return {
    //     ...prev,
    //   };
    // });
    setOpen(false);
    setChecked({
      checkedC: false,
      checkedU: false,
      checkedR: false,
      checkedD: false,
    });
  };

  useEffect(() => {
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      localStorage.setItem("metadataId", params.get("metadata_id"));
      localStorage.setItem("projectName", params.get("projectName"));
    }
    getProjectDetails();

    getEvent();
  }, []);

  const getProjectDetails = async () => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${localStorage.getItem(
          "metadataId"
        )}'`,
        return_fields:
          "merge(keep(projectvstools,'projectid','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
      })
      .then((response) => {
        if (response.data.result[response.data.result.length - 1].projectlang) {
          setProjectDetails({
            clientid:
              response.data.result[response.data.result.length - 1].clientid ??
              "",
            projectid:
              response.data.result[response.data.result.length - 1].projectid ??
              "",
          });
          localStorage.setItem(
            "projectid",
            response.data.result[response.data.result.length - 1].projectid ??
              ""
          );
          localStorage.setItem(
            "clientid",
            response.data.result[response.data.result.length - 1].clientid ?? ""
          );

          props.backDrop.setBackDrop({
            open: false,
            message: "",
          });
        }
      })
      .catch((err) => {});
  };

  // Event Logging
  const getEvent = async () => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });

    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: `event_catalog`,
        filter: `event_catalog.projectid == '${localStorage.getItem(
          "projectid"
        )}' && event_catalog.activestatus==true`,
        return_fields: `event_catalog`,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.result.length > 0) {
          let resultData = response.data.result;
          eventDetailsRef.current = response.data.result;
          let result = resultData.reduce((evns, evn) => {
            let evnsObj = {
              EventName: evn.eventname,
              id: evn.id,
              FunctionGroup: evn.functiongroup,
              ScreenName: evn.screenname,
              EventCode: evn.eventcode,
              EventDisc: evn.eventdisc,
              Create: evn.create,
              Delete: evn.delete,
              Update: evn.update,
              Read: evn.read,
            };
            return [...evns, evnsObj];
          }, []);
          setEventDetails(result);
          console.log(eventDetails, "eventDetailsssssssss");
        }
      });
  };

  console.log(eventDetails, "EventDetails");

  const drawerClosing = () => {
    setValueChange({});
    setDraweropen(false);
    setChecked({
      checkedC: false,
      checkedU: false,
      checkedR: false,
      checkedD: false,
    });
  };
  const drawerOpen = () => {
    let fields = {};
    drawerPref.map((field) => {
      fields[field.titles] = "";
    });

    setDraweropen(true);
  };

  // On Add Value change
  const valueChanging = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setValueChange((prev) => {
      return {
        ...prev,
        id: uuidv4(),
        [fieldName]: fieldValue,
      };
    });
  };

  // Add
  const onAdd = async () => {
    let countList = Object.values(valueChange).filter(
      (data) => data.length > 0
    );

    if (countList.length < 2) {
      props.alert.setSnack({
        open: true,
        msg: "Please Fill the  Data",
        severity: "info",
      });
      return;
    }

    var dup;
    setDupVal((prev) => {
      dup = { ...prev, ...valueChange };
      return { ...prev, ...valueChange };
    });
    console.log(dup, "Inside Dup");

    setEventDetails((prev) => {
      return [...prev, dup];
    });

    setDraweropen(false);
    setValueChange({});
    console.log(eventDetails, "EventDetails");
  };
  console.log("DubValue", dupVal);
  const [deleteState, setDeleteState] = useState({});
  const [deleteDrawer, setDeleteDrawer] = useState(false);

  const deleteRow = (languages) => {
    const newRow = eventDetails.filter((itm) => itm.id !== languages.id);
    setEventDetails(newRow);
    handleDeleteDrawerClose();
  };

  const handleDelete = (lang) => {
    setDeleteState(lang);
    setDeleteDrawer(true);
  };

  const handleDeleteDrawerClose = () => {
    setDeleteDrawer(false);
  };

  // Edit Switch check
  const onEditCheck = (e) => {
    debugger;
    const editname = e.target.name;
    const editCheck = e.target.checked;

    setEditValue((prev) => {
      return {
        ...prev,
        [editname]: editCheck,
      };
    });
  };

  // On Editing
  const onEditing = (e) => {
    const editname = e.target.name;
    const editvalue = e.target.value;

    console.log(e.target.checked, "CheckedTarget");

    setEditValue((prev) => {
      return {
        ...prev,
        [editname]: editvalue,
      };
    });

    setValueChange((prev) => {
      return {
        ...prev,
        [editname]: editValue,
      };
    });
  };
  console.log(editValue, "EditValueRRRR");
  console.log(editValue.Update, "Update");

  //Edit Add
  const onEditAdds = () => {
    debugger;
    let _eventDetails = eventDetails?.map((_e) => {
      if (editValue?.id && _e?.id === editValue?.id) {
        _e = editValue;
      }
      return _e;
    });
    setEventDetails(_eventDetails);
    setOpen(false);
    setValueChange({});
  };

  // SaveEvent
  const saveEventDetails = async () => {
    debugger;
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });

    let allPromises = [];
    let createdMsgCatalogs = [];
    let updatedMsgCatalogs = [];

    eventDetailsRef.current.forEach((eves) => {
      if (!eventDetails.some((eve) => eve.id === eves?.id)) {
        let result = {
          entity: "event_catalog",
          filter: {
            id: eves.id,
          },
        };

        let msgDeleteRequest = deleteDocument([result]);
        allPromises.push(msgDeleteRequest);
      }
    });
    console.log("EditValue", editValue);

    eventDetails.map((events) => {
      let result = eventDetailsRef.current.find(
        (eves) => eves.id === events.id
      );
      console.log(events, "THis EventDetails");
      console.log(result, "this is Result");

      if (result) {
        let createdSchema = {
          clientid: projectDetails.clientid,
          projectid: projectDetails.projectid,
          eventname: events?.EventName,
          id: events?.id,
          functiongroup: events?.FunctionGroup,
          screenname: events?.ScreenName,
          eventcode: events?.EventCode,
          eventdisc: events?.Description,
          create: events.Create,
          update: events.Update,
          read: events.Read,
          delete: events.Delete,
          metadataid: localStorage.getItem("metadataId"),
        };
        updatedMsgCatalogs.push({
          entity: "event_catalog",
          body: createdSchema,
          filter: {
            id: events.id,
          },
        });
      } else {
        let updatedSchema = {
          clientid: projectDetails.clientid,
          projectid: projectDetails.projectid,
          eventname: events?.EventName,
          id: events?.id,
          functiongroup: events?.FunctionGroup,
          screenname: events?.ScreenName,
          eventcode: events?.EventCode,
          eventdisc: events.Description,
          create: events.Create,
          update: events.Update,
          read: events.Read,
          delete: events.Delete,
          metadataid: localStorage.getItem("metadataId"),
        };
        createdMsgCatalogs.push({
          entity: "event_catalog",
          body: updatedSchema,
        });
      }
    });

    if (createdMsgCatalogs.length > 0) {
      let createdMsgCatalogRequest = createDocument(createdMsgCatalogs);
      allPromises.push(createdMsgCatalogRequest);
    }
    if (updatedMsgCatalogs.length > 0) {
      let updatedMsgCatalogRequest = updateDocument(updatedMsgCatalogs);
      allPromises.push(updatedMsgCatalogRequest);
    }

    Promise.all(allPromises)
      .then(async (result) => {
        console.log("result:\n" + JSON.stringify(result));
        props.alert.setSnack({
          open: true,
          msg: "Event Logging save successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to do the operations");
        console.error(err);
        props.alert.setSnack({
          open: true,
          msg: "Event Logging save faild !",
          severity: "error",
        });
      });
    props.backDrop.setBackDrop({
      open: false,
      message: "",
    });

    setValueChange({});

    setChecked({
      checkedC: false,
      checkedU: false,
      checkedR: false,
      checkedD: false,
    });

    console.log(valueChange, "ValueChange");
  };

  const drawerClose = () => {
    setEditOpen({
      ...isEditOpen,
      isOpen: false,
    });
  };
  const closeIsDrawerOpen = () => {
    setValueChange({});

    setDraweropen(false);
    setChecked({
      checkedC: false,
      checkedU: false,
      checkedR: false,
      checkedD: false,
    });
  };
  const [loader, setLoader] = useState(true);
  const closeLoader = () => {
    setLoader(false);
  };

  return (
    <div className={classes.root}>
      {/* <Dialog 
      open={loader}
      handleClose={()=>closeLoader()}
      transprant={true}
      component={
        <CircularProgress color="secondary" style={{margin: "auto"}}/>
      }
      /> */}
      <Dialog
        open={deleteDrawer}
        handleClose={() => handleDeleteDrawerClose()}
        component={
          <div>
            <div style={{ padding: "10px", textAlign: "center" }}>
              <Typography
                className={classes.title}
                style={{ fontSize: "1.2rem" }}
              >
                Are you sure, you want to delete?
              </Typography>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "15px",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={() => handleDeleteDrawerClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRow(deleteState)}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />
      {/* {messageAlert.isopen && (
          <Alert
            severity={messageAlert.snacktype}
            open={messageAlert.isopen}
            vertical={"top"}
            horizontal={"right"}
            msg={messageAlert.snackmsg}
            onclose={() => closeSnackBar()}
          />
        )} */}
      <Header headerName={messageHeaderName}></Header>
      <div className={classes.container}>
        <div className={classes.tabAlign}>
          <Typography style={{ fontSize: "24px", flexGrow: 1 }}>
            Event Logging
          </Typography>
          <Button
            onClick={drawerOpen}
            variant="contained"
            style={{ marginRight: "10px" }}
          >
            add
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveEventDetails()}
          >
            Save
          </Button>
        </div>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {drawerPref.map((itm) =>
                    Object.keys(itm).map((key) =>
                      key !== "id" &&
                      key !== "EventDisc" &&
                      key !== "Create" &&
                      key !== "Delete" &&
                      key !== "Read" &&
                      key !== "Update" &&
                      key !== "Description" ? (
                        <TableCell>{itm[key]}</TableCell>
                      ) : (
                        ""
                      )
                    )
                  )}
                  <TableCell key={1} align="left">
                    Actions
                  </TableCell>

                  {/* <TableCell align="left">Function Group</TableCell>
                  <TableCell align="left">Screen</TableCell>
                  <TableCell align="left">Event Code</TableCell>
                  <TableCell align="left">Actions</TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {eventDetails?.length <= 0 ? (
                  <>
                    <TableRow height="200">
                      <div
                        style={{
                          position: "absolute",
                          width: "92%",
                          textAlign: "center",
                          marginTop: "30px",
                        }}
                      >
                        <span>No Data Found</span>
                      </div>
                    </TableRow>
                  </>
                ) : (
                  ""
                )}
                {eventDetails.map((events, index) => (
                  <TableRow className={classes.tableRow}>
                    {Object.keys(events).map((key) =>
                      key === "EventName" ||
                      key === "FunctionGroup" ||
                      key === "EventCode" ||
                      key === "ScreenName" ? (
                        <TableCell>{events[key]}</TableCell>
                      ) : (
                        ""
                      )
                    )}
                    <TableCell
                      key={1}
                      align="left"
                      style={{ display: "flex", padding: "0px !important" }}
                    >
                      <div style={{ marginLeft: "-18px" }}>
                        <IconButton onClick={(e) => editOpen(events, index)}>
                          <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton onClick={(e) => handleDelete(events)}>
                          <HighlightOffIcon></HighlightOffIcon>
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      {/* ----------------------------------- Drawer ----------------------------------------------------- */}
      <Drawer
        open={isdrawerOpen}
        onClose={() => closeIsDrawerOpen()}
        anchor="right"
      >
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            Add Event
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.titles}
                    onChange={valueChanging}
                    value={valueChange?.[item.titles]}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.FunctionGroup}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.FunctionGroup}
                    onChange={valueChanging}
                    value={valueChange[item.FunctionGroup]}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.ScreenName}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.ScreenName}
                    onChange={valueChanging}
                    value={valueChange[item.ScreenName]}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.EventCode}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.EventCode}
                    onChange={valueChanging}
                    value={valueChange[item.EventCode]}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.EventDisc}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.EventDisc}
                    onChange={valueChanging}
                    value={valueChange[item.EventDisc]}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <Typography>{item.Create}:</Typography>
                    <Switch
                      name={item.Create}
                      checked={checked.checkedC}
                      onChange={handleSwitch}
                      value="checkedC"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>{item.Update}:</Typography>
                    <Switch
                      name={item.Update}
                      checked={checked.checkedU}
                      onChange={handleSwitch}
                      value="checkedU"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <Typography>{item.Read}:</Typography>
                    <Switch
                      name={item.Read}
                      checked={checked.checkedR}
                      onChange={handleSwitch}
                      value="checkedR"
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    <Typography>{item.Delete}:</Typography>
                    <Switch
                      name={item.Delete}
                      checked={checked.checkedD}
                      onChange={handleSwitch}
                      value="checkedD"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={drawerClosing}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onAdd}>
              Add
            </Button>
          </div>
        </div>
      </Drawer>

      {/* ============================ Edit Drawer ===================================== */}
      <Drawer open={open} onClose={() => drawerClose()} anchor="right">
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            Edit Event
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.titles}
                    onChange={onEditing}
                    value={editValue.EventName}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.FunctionGroup}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.FunctionGroup}
                    onChange={onEditing}
                    value={editValue.FunctionGroup}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.ScreenName}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.ScreenName}
                    onChange={onEditing}
                    value={editValue.ScreenName}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.EventCode}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.EventCode}
                    onChange={onEditing}
                    value={editValue.EventCode}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.EventDisc}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name={item.EventDisc}
                    onChange={onEditing}
                    value={editValue.EventDisc}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <Typography>{item.Create}:</Typography>
                    <Switch
                      name={item.Create}
                      checked={editValue.Create}
                      onChange={onEditCheck}
                      value="checkedC"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>{item.Update}:</Typography>
                    <Switch
                      name={item.Update}
                      checked={editValue.Update}
                      onChange={onEditCheck}
                      value="checkedU"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <Typography>{item.Read}:</Typography>
                    <Switch
                      name={item.Read}
                      checked={editValue.Read}
                      onChange={onEditCheck}
                      value="checkedR"
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    <Typography>{item.Delete}:</Typography>
                    <Switch
                      name={item.Delete}
                      checked={editValue.Delete}
                      onChange={onEditCheck}
                      value="checkedD"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={editClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onEditAdds}>
              Update
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default withAllContexts(EventLogging);
